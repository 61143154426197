/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Teachers:ital,wght@0,400..800;1,400..800&display=swap'); */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 20px;
    min-width: 300px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-close-button {
    height: 25px;
    width: 25px;
    background: #ccc;
    border-radius: 100px;
    /* padding: 5px; */
    border: none;
    /* font-size: 24px; */
    cursor: pointer;
    margin-bottom: 10px;
}

.modal-close-button:hover {
    background-color: rgb(78, 78, 78);
    color: white;
}

.note-input {
    padding: 10px;
    text-align: center;
    font-size: large;
    width: 350px;
    background-color: #EEEEEE;
    font-weight: 500;
    outline: none;
    margin-bottom: 30px;
}

.submit-button {
    padding: 10px 20px;
    font-size: large;
    font-weight: bold;
    color: #28fc03;
    background-color: #414141;
    /* margin-top: 30px; */
}

.submit-button:hover {
    background-color: #696969;
}

.modal-input {
    width: 100%;
    padding: 11px;
    background-color: #e0e0e0;
    outline: none;
    border: none;
    border-radius: 5px;
}

/* .modal-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
} */

.modal-input::placeholder {
    color: rgb(59, 59, 59);
    font-size: large;
}